import React, { useState, useEffect } from 'react';
import Slider from './Slider';

function DataSlider({ data, day }) {

  const [item, setItem] = useState({ colomename: [], count: [] });

  useEffect(() => {
    // Fetch Data from Backend API
    const fetchData = async () => {
      const response = await fetch(`https://checkmateelection.com/trialnew/last_day/card1.php?boothno=${data}&status=${day}`);
      const jsonData = await response.json();
      setItem(jsonData);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 60000); // 20 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);

  }, [data, day]);

  const titles = {
    "tvoter": "Total Voter",
    "boothno": "Booth Number",
    "pendinvoter": "Pending Voters",
    "donevoter": "Voting Done",
    "tmuslim":"Total Muslim",
    "donmuslim":" Muslim Vote Count",
    "nonmuslim":"Total Non Muslim",
    "donnonmuslim":"non muslim Vote "
  };

  return (
    <Slider>
      {
        item.colomename.map((columnName, index) => (
          <div key={index} className="card flex m-2 text-center" style={{ 'width': '10rem', 'height': '70px', 'fontSize': '12px' }}>
            <div className="card-header mt-0">
              <b style={{ 'fontSize': '15px', 'fontFamily': 'sans-serif' }}>
                {titles[columnName] || columnName} {/* Display title from `titles` if available */}
                <br />
                <p>{item.count[index]}</p> 
              </b>
            </div>
            
          </div>
        ))
      }
    </Slider>
  );
}

export default DataSlider;
