import React from 'react'
import Navbar from './Navbar'

import NavbarSecond from './NavbarSecond'
function Home() {
  return (
    <div className='Main-Home' style={{"background":"#1A2130",'height':100+'vh','width':100+'vw'}}>
    <Navbar />
    <NavbarSecond />
   
    </div>
  )
}

export default Home