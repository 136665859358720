import React, { useRef } from 'react'
import left from '../images/left-slide.png'
import right from '../images/right-slide.png'
import '../css/Slider.css'

function Slider({ children }) {

  const scrollContainer = useRef(null);

  // Function to scroll left
  const scrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: -200, // Change this value to adjust scroll amount
        behavior: 'smooth'
      });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: 200, // Change this value to adjust scroll amount
        behavior: 'smooth'
      });
    }
  };

  return (<>
    <div className='container-fluid d-flex  mt-2 w-80  ' style={{ 'background': 'whitesmoke', 'border': 1 + 'px solid black' }}>
      <div  >
        <img src={left} alt='not found' className='mt-4' onClick={scrollLeft} style={{ 'width': 30 + 'px', 'height': 30 + 'px', 'margin-right': 20 + 'px', 'background': '#9DB2BF', 'foreground': 'white', 'border-radius': 50 + 'px' }} />
      </div>
      <div ref={scrollContainer} className=' item-slider-container   overflow-hidden ' style={{ 'background': '#9DB2BF' }}>

        <div className='item-slider d-flex gap-3 '>

          <div className="item-container d-flex gap-3 ">
            {children}
          </div>

        </div>
      </div>
      <div  >
        <img src={right} alt='not found' onClick={scrollRight} className='mt-4 ' style={{ 'width': 30 + 'px', 'height': 30 + 'px', 'margin-left': 10 + 'px', 'background': '#9DB2BF', 'foreground': 'white', 'border-radius': 50 + 'px' }} />
      </div>
    </div>
  </>
  )
}

export default Slider