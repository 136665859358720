import React from "react";
import logo from "../images/BJP-Logo.png";
import "../css/Navbar.css";


function Navbar() {

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light justify-content-center">
       
        <h5 style={{ color: 'white', width: '2vw ' }}>Sindhkheda </h5>

      </nav>
    </div>

  );
}

export default Navbar;
