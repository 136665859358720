import React, { useState, useEffect } from 'react';
import '../css/Table.css';
import left from '../images/left-arrow.png';
import right from '../images/right-arrow.png';
import * as XLSX from 'xlsx';
import CustomAlert from './CustomeAlert'; // Import the custom alert component

function Table({ consti, day, pdf, xls, summ }) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [alertData, setAlertData] = useState({ show: false, message: '' });
  const [dataSumm, setDataSumm] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state

  // Fetch data based on 'summ' prop (summary or user data)
  const fetchData = async (page) => {
    try {
      let url = '';
      if (summ ) {
        // Fetch summary data when `summ` is true
        url = `https://checkmateelection.com/trialnew/last_day/summry.php?page=${page}&page_size=12&boothno=0`;
      } else {
        // Fetch user data when `summ` is false
        url = `https://checkmateelection.com/trialnew/last_day/table.php?page=${page}&page_size=12&boothno=${consti}&status=${day}`;
      }

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch data from the server');
      }

      const jsonData = await response.json();
      setData(jsonData.result);
      setTotalPages(jsonData.total_page);
    } catch (error) {
      console.error('Error:', error);
      setAlertData({ show: true, message: 'An error occurred while fetching the data. Please check your network or try again later.' });
    }
  };

  const fetchDataExcel = async () => {
   

    try {
      let url = '';
      if (summ) {
        setLoading(true); // Start loading
        setAlertData({ show: true, message: 'Data is being processed, please wait...' }); // Show custom alert
        // Fetch summary data when `summ` is true
        url = `https://checkmateelection.com/trialnew/last_day/exceltable.php?boothno=0`;
      } else {
        // Fetch user data when `summ` is false
        url = `https://checkmateelection.com/trialnew/last_day/exceltable.php?boothno=${consti}`;
      }

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch data from the server');
      }

      const jsonData = await response.json();
      setDataSumm(jsonData.result);

      // Simulate a delay before hiding the loading alert (to give users time to read)
      setTimeout(() => {
        setLoading(false); // Stop loading
        setAlertData({ show: false, message: '' }); // Hide the alert
      }, 2000); // Keep the alert visible for 3 seconds

    } catch (error) {
      console.error('Error:', error);
      setAlertData({ show: true, message: 'An error occurred while fetching the data. Please check your network or try again later.' });
      setLoading(false); // Stop loading in case of an error
    }
  };

  useEffect(() => {
    fetchDataExcel();
  }, [consti, summ]);

  // Fetch data when the page, booth, day, or summary changes
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, consti, day, summ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData(currentPage); // Re-fetch data every 60 seconds
    }, 60000); // 60000 ms = 60 seconds

    // Clean up the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [currentPage, consti, day, summ]);

  // Excel export function
  useEffect(() => {
    if (xls && !loading) {  // Only generate Excel file if data is loaded
      const columnNames = summ
        ? ['Booth Number', 'Booth Name', 'village Name', 'Total Voter', 'Total Vote Done', 'Total Vote Done (%)', 'Total Vote NotDone', 'Total Vote NotDone (%)']
        : ['Booth Number', 'Voter Number', 'Voter Name', 'Card Number', 'Voting Status', 'Address'];

      const columnValues = dataSumm.map(dat => 
        summ
          ? [
              dat.boothno, 
              dat.boothname, 
              dat.villageno, 
              dat.tvoter, 
              dat.votedone, 
              dat.pdonevoter, 
              dat.notdone, 
              dat.pnotdonevoter,
            ]
          : [
              dat.boothno, 
              dat.voterno, 
              dat.votername, 
              dat.cardno, 
              dat.rationcardno == 0 ? 'pending' : 'done', 
              dat.address
            ]
      );

      const wb = XLSX.utils.book_new();
      const datas = [columnNames, ...columnValues];
      const ws = XLSX.utils.aoa_to_sheet(datas);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = summ ? 'summary.xlsx' : 'data.xlsx';
      link.click();
    }
  }, [dataSumm, xls, summ, loading]);

  // Pagination functions
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <>
      {/* Display the custom alert */}
      <CustomAlert
        show={alertData.show}
        message={alertData.message}
        onClose={() => setAlertData({ show: false, message: '' })}
      />

      {/* Render the table depending on whether it's summary or user data */}
      {summ ? (
        <div className="container-fluid container-m mt-5">
          <table className="table table-bordered mt-1">
            <thead>
              <tr id="main-row" style={{ border: '1px solid black' }}>
                <th scope="col" colSpan={7}><h6>Summary</h6> </th>
              </tr>
              <tr>
                <th scope="row" id="row-o">Booth No</th>
                <th scope="col" id="row-o">Booth Name</th>
                <th scope="col" id="row-j">Total Vote</th>
                <th scope="col" id="row-j">Total Vote Done</th>
                <th scope="col" id="row-m">Total Vote Done (%)</th>
                <th scope="col" id="row-m">Total Vote NotDone</th>
                <th scope="col" id="row-j">Total Vote NotDone(%)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dat, key) => (
                <tr key={key + 1}>
                  <td>{dat.boothno}</td>
                  <td>{dat.boothname}</td>
                  <td>{dat.tvoter}</td>
                  <td>{dat.votedone}</td>
                  <td>{dat.pdonevoter} %</td>
                  <td>{dat.notdone}</td>
                  <td>{dat.pnotdonevoter} %</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="container-fluid container-m mt-5">
          <table className="table table-bordered mt-1">
            <thead>
              <tr id="main-row" style={{ border: '1px solid black' }}>
                <th scope="col" colSpan={6}><h6>User Details</h6> </th>
              </tr>
              <tr>
                <th scope="row" id="row-o">Booth Number</th>
                <th scope="col" id="row-o">Voter Number</th>
                <th scope="col" id="row-o">Voter Name</th>
                <th scope="col" id="row-j">Voter Card</th>
                <th scope="col" id="row-j">Voting Status</th>
                <th scope="col" id="row-j">Address</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dat, key) => (
                <tr key={key + 1}>
                  <td>{dat.boothno}</td>
                  <td>{dat.voterno}</td>
                  <td>{dat.votername}</td>
                  <td>{dat.cardno}</td>
                  <td className={dat.rationcardno == 1 ? 'done' : 'notdone'}>
                    {dat.rationcardno == 0 ? 'pending' : 'done'}
                  </td>
                  <td>{dat.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="pagination mb-2 mt-1">
        <img
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          src={left}
          alt="previous"
          style={{ background: 'white', borderRadius: '50px', marginRight: '10px' }}
        />
        <span style={{ color: 'white' }}>
          {currentPage} of {totalPages}
        </span>
        <img
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          src={right}
          alt="next"
          style={{ background: 'white', borderRadius: '50px', marginLeft: '10px' }}
        />
      </div>
    </>
  );
}

export default Table;
