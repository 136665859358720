import React, { useState } from 'react';
import '../css/Login.css';
import logo from '../images/BJP-Logo.png';
import key from '../images/key.png';
import lock from '../images/lock.png';
import CustomAlert from './CustomeAlert';


function Login({ onLogin }) {
  const [passVisible, setPassVisible] = useState(false);
  const [uname, setUname] = useState('');
  const [password, setPassword] = useState('');
  const [alertData, setAlertData] = useState({ show: false, message: '' });

  const validation = (e) => {
    e.preventDefault();

    if (uname === 'admin' && password === 'pass@123') {
      setAlertData({ show: true, message: 'Login Successful' });
      onLogin(); // Trigger the onLogin function passed as a prop
    } else {
      setAlertData({ show: true, message: 'Login Failed' });
    }
  };

  const closeAlert = () => {
    setAlertData({ show: false, message: '' }); // Close the alert
  };

  return (
    <div>
      {/* Show CustomAlert when alertData.show is true */}
      <CustomAlert show={alertData.show} message={alertData.message} onClose={closeAlert} />
      
      <div className="container container-x mt-5 justify-content-center">
        <form className="mt-5 justify-content-center" onSubmit={validation}>
          <div className="header w-20">
           
            <h2 style={{color:"black"}}>Login</h2>
          </div>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">User Name</label>
            <input
              type="text"
              className="form-control form-mail"
              id="username"
              value={uname}
              onChange={(e) => setUname(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 pass">
            <div className="pass-lock">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type={passVisible ? 'text' : 'password'}
                className="form-control w-100"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <img
              src={passVisible ? key : lock}
              alt={passVisible ? "Hide password" : "Show password"}
              onClick={() => setPassVisible(!passVisible)}
            />
          </div>
          <button type="submit" className="btn btn-primary w-50">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
