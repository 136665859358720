import React from 'react';

const CustomConfirm = ({ show, message, onConfirm, onCancel }) => {
    if (!show) return null;

    // Inline styles for the confirmation dialog
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    const boxStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '300px',
        textAlign: 'center',
    };

    const buttonStyle = {
        margin: '0 10px',
        padding: '8px 16px',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '4px',
        fontSize: '16px',
    };

    const confirmButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#28a745',
        color: 'white',
    };

    const cancelButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#dc3545',
        color: 'white',
    };

    return (
        <div style={overlayStyle}>
            <div style={boxStyle}>
                <p>{message}</p>
                <div className="buttons" style={{ marginTop: '20px' }}>
                    <button onClick={onConfirm} style={confirmButtonStyle}>
                        Yes
                    </button>
                    <button onClick={onCancel} style={cancelButtonStyle}>
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomConfirm;
