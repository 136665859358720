import React, { useState } from 'react';
import '../css/Form.css';
import CustomAlert from './CustomeAlert'; // Import the custom alert component
import CustomConfirm from './CustomConfirm'; // Import the custom confirm component

function Form({ booth }) {
    const [voterNumber, setVoterNumber] = useState('');
    const [alertData, setAlertData] = useState({ show: false, message: '' }); // State for alert
    const [confirmData, setConfirmData] = useState({ show: false, message: '', onConfirm: null }); // State for confirmation
    
    const handleSubmit = async () => {
        if (!voterNumber) {
            setAlertData({ show: true, message: 'Please fill in the Voter Number field.' });
            return;
        }

        // Show the custom confirmation dialog instead of window.confirm
        setConfirmData({
            show: true,
            message: `Are you sure you want to submit voter number "${voterNumber}" with booth "${booth}"?`,
            onConfirm: async () => {
                try {
                    // Backend API call for submission
                    const response = await fetch('https://checkmateelection.com/trialnew/last_day/insert.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "voterno": voterNumber,
                            "boothno":booth,
                            action: 'submit',
                        }),
                    });

                    if (response.ok) {
                        const result = await response.json();
                        setAlertData({ show: true, message: `Success` });
                       
                        setVoterNumber(''); // Reset the form
                    } else {
                        setAlertData({ show: true, message: 'Failed to submit. Please try again.' });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    setAlertData({ show: true, message: 'An error occurred. Please check your network or try again later.' });
                }

                setConfirmData({ show: false, message: '', onConfirm: null }); // Hide confirmation
            },
        });
    };

    const handleRevoke = async () => {
        if (!voterNumber) {
            setAlertData({ show: true, message: 'No data to revoke.' });
            return;
        }

        // Show the custom confirmation dialog instead of window.confirm
        setConfirmData({
            show: true,
            message: `Are you sure you want to revoke the submission for voter number "${voterNumber}" with booth "${booth}"?`,
            onConfirm: async () => {
                // Send the last updated data with the 'revoke' action
                try {
                    const response = await fetch('https://checkmateelection.com/trialnew/last_day/insert.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "voterno": voterNumber,
                            "boothno": booth,
                            'revoke' :true
                        }),
                    });

                    if (response.ok) {
                        const result = await response.json();
                        setAlertData({ show: true, message: `Revoke success` });
                        setVoterNumber(''); // Reset form
                        
                    } else {
                        setAlertData({ show: true, message: 'Failed to revoke. Please try again.' });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    setAlertData({ show: true, message: 'An error occurred. Please check your network or try again later.' });
                }

                setConfirmData({ show: false, message: '', onConfirm: null }); // Hide confirmation
            },
        });
    };

    const closeAlert = () => {
        setAlertData({ show: false, message: '' });
    };

    const closeConfirm = () => {
        setConfirmData({ show: false, message: '', onConfirm: null }); // Close the confirm dialog
    };

    return (
        <div>
            {/* Display the custom alert */}
            <CustomAlert
                show={alertData.show}
                message={alertData.message}
                onClose={closeAlert}
            />

            {/* Display the custom confirm */}
            <CustomConfirm
                show={confirmData.show}
                message={confirmData.message}
                onConfirm={confirmData.onConfirm}
                onCancel={closeConfirm}
            />

            <div className="container-flex con-d justify-content-center d-flex gap-2 mt-2 nav p-2">
                <div className="form-f d-flex gap-2">
                    <div className="header">
                        <h5>Update Voting</h5>
                    </div>
                    <div className="input-box">
                        <input
                            type="text"
                            placeholder="Voter Number"
                            value={voterNumber}
                            onChange={(e) => setVoterNumber(e.target.value)} // Update state
                        />
                    </div>
                </div>
                <div className="buttons container-flex d-flex justify-content-start gap-3">
                    <button className="btn btn-success" onClick={handleSubmit}>
                        Submit
                    </button>
                    <button className="btn btn-danger ml-5" onClick={handleRevoke}>
                        Revoke
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Form;
