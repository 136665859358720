import React from 'react';

function CustomAlert({ show, message, onClose }) {
  if (!show) return null; // Don't render if show is false

  return (
    <div
      className="modal show"
      style={{
        display: 'block', // Ensure it's visible when show is true
        backgroundColor: 'rgba(0,0,0,0.5)', // Overlay effect for background
        zIndex: 1050, // Ensures it appears on top of other elements
      }}
      aria-labelledby="custom-alert"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="custom-alert">Alert</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomAlert;
