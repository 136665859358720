import React, { useState, useEffect } from 'react';
import download from '../images/download.png';
import '../css/NavbarSecond.css';
import Table from './Table';
import DataSlider from './DataSlider';
import Form from './Form';

function NavbarSecond() {
  const [drop, setDrop] = useState([]);
  const [option, setOption] = useState('0');
  const [day, setDay] = useState(0);
  const [pdf, setPdf] = useState(false);
  const [xls, setXls] = useState(false);
  const [summ, setSummery] = useState(false); // Summary flag

  useEffect(() => {
    // Fetch dropdown data from backend
    const fetchData = async () => {
      const response = await fetch('https://checkmateelection.com/trialnew/last_day/dropdown.php');
      const jsonData = await response.json();
      setDrop(jsonData);
    };
    fetchData();
  }, []);

  // Handle Excel export
  const handleXls = () => {
    setXls(true);
    setTimeout(() => {
      setXls(false);
    }, 1000);
  };

  // Handle day change and reset summary
  const handleDayChange = (newDay) => {
    setDay(newDay);
    setSummery(false); // Reset summary view when changing day
  };

  return (
    <>
      <div className="container-fluid justify-content-start d-flex gap-2 mt-2 nav p-2">
        <form action="" onSubmit={(e) => e.preventDefault()} className="form-inline my-2 my-lg-0">
          <div className="buttons container-flex d-flex justify-content-start gap-3 ">
            <button
              className="btn btn-x btn-info"
              onClick={() => handleDayChange(1)}
              style={{ height: '20%' }}
            >
              Voting Done
            </button>
            <button
              className="btn btn-x btn-warning"
              onClick={() => handleDayChange(0)}
              style={{ height: '20%' }}
            >
              Pending Vote
            </button>
            <button
              className="btn btn-x btn-success"
              onClick={() => handleDayChange(-1)}
              style={{ height: '20%' }}
            >
              All Record
            </button>
          </div>
        </form>

        <div className="buttons container-flex d-flex justify-content-start gap-3">
          <select
            name=""
            id=""
            value={option}
            className="select nav-item"
            onChange={(e) => setOption(e.target.value)}
            style={{ fontSize: '13px', fontFamily: 'sans-serif' }}
          >
            <option value="0">Consitancy Data</option>
            {drop.map((val, key) => (
              <option key={key} value={val.boothno}>
                {val.boothname}
              </option>
            ))}
          </select>

          <button
            className="btn btn-light btn-y"
            style={{ color: 'black' }}
            onClick={() => setSummery(true)} // Show summary
          >
            {' '}
           Summary
          </button>
          <button
            className="btn btn-light btn-y"
            style={{ color: 'black' }}
            onClick={handleXls}
          >
            <img
              src={download}
              alt="not found"
              style={{ width: '20px', height: '20px' }}
            />{' '}
            Excel
          </button>
        </div>
      </div>

      <DataSlider data={option} day={day} />
      <Form booth={option==0?1:option} />
      <Table consti={option==0?1:option} day={day} pdf={pdf} xls={xls} summ={summ} />
    </>
  );
}

export default NavbarSecond;
